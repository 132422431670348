import React from 'react';
import './home.css';
import { Apropos, Competences, Contact, Formations, Navbar, Outils } from '../../containers';

const Home = () => {
    return (
      <div id="home">
        <Navbar />
        <div id="container">
          <Apropos />
        </div>
        <Competences />
        <div id="container">
          <Outils />
        </div>
        <div id="container">
          <Formations />
        </div>
          <Contact />
      </div>
    );
}

export default Home;
