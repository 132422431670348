import React from 'react';
import './contact-box.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactBox = ({ icon, text, titre }) => {
    return (
      <div id="contact-box">
          <div className="item">
              {icon && <FontAwesomeIcon className={'fill-red'} icon={icon} />}
              {titre && <h2 className="text-xl">{titre}</h2>}
              <div className="text">{text}</div>
          </div>
      </div>
    );
};

export default ContactBox;
