import React from 'react';


const Error404 = () => {
    return (
      <div id="Error404">
         <h1>Tiens mais tu cherche à aller où ?</h1>
      </div>
    );
};

export default Error404;
