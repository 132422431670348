import React from 'react';
import './outils.css';
import slack from './images/slack.png';
import trello from './images/trello.png';
import miro from './images/miro.png';
import figma from './images/figma.png';
import docker from './images/docker.png';
import github from './images/github.png';

const Outils = () => {
    return (
      <div id="outils">
        <h2 className="text-3xl uppercase text-white">mes outils</h2>
        <div className="logos">
          <img src={slack} alt="slack" />
          <img src={trello} alt="trello"/>
          <img src={miro} alt="Miro"/>
          <img src={figma} alt="Figma"/>
          <img src={github} alt="Github"/>
          <img src={docker} alt="Docker"/>
        </div>
      </div>
    );
}

export default Outils;
