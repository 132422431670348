import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './pages';
import { Error404 } from './containers';


const Nav = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='*' element={<Error404/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default Nav;
