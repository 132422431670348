import React from 'react';
import './formation.css';

const Formation = ({ formation }) => {
    const { name, date_start, date_end, image } = formation;
    return (
      <div id="formation" className="animate__animated animate__slideInLeft">
        <img src={image} className='border-b-2' alt={`logo de ${name}`}/>
        <div className="pt-2 flex-1 flex flex-col justify-center">
          <h3>{name}</h3>
          <p>{date_start} - {date_end}</p>
        </div>
      </div>
    );
}

export default Formation;
