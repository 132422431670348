import React from 'react';
import formations from './formations.json';
import { Formation } from '../../components';
import './formations.css';
const Formations = () => {

    return (
      <div id="formations">
        <h2 className="text-3xl uppercase text-white">mes formations</h2>
        <div className="container-formations">
          {
              formations.map((formation) => <Formation key={formation.name} formation={formation} />)
          }
        </div>
      </div>
    );
}

export default Formations;
