import React, { useState } from 'react';
import './navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isHidden, setIsHidden] = useState(true);
  const [scrollBottom, setScrollBottom] = useState(false);
  window.addEventListener('scroll',(event) => {
    setScrollBottom(window.scrollY > 60);
  });
  return (
      <div id="navbar">
        <div className={`navbar-mobile ${scrollBottom ? 'p-6' : 'py-16'}`}>
          <button className={'text-2xl'}>Gabriel Chirouse</button>
          <FontAwesomeIcon size="2x" onClick={() => setIsHidden(!isHidden)} className={'lg:hidden'} icon={faBars} />
          <ul className={`lg:navbar-elements`}>
            <li><a href={'#apropos'}>À propos</a></li>
            <li><a href={'#competences'}>Compétences</a></li>
            <li><a href={'#outils'}>Outils</a></li>
            {/*<li><a href={'#projets'}>Projets</a></li>*/}
            <li><a href={'#formation'}>Formations</a></li>
            <li><a href={'#contact'}>Contact</a></li>
          </ul>
        </div>
        <div className={`navbar-menu ${isHidden ? 'animate__animated animate__slideOutUp' : 'animate__animated animate__slideInDown'}`}>
          <ul className={`navbar-elements ${isHidden && 'hidden'}`}>
            <li><a href={'#apropos'} onClick={() => setIsHidden(true)}>À propos</a></li>
            <li><a href={'#competences'} onClick={() => setIsHidden(true)}>Compétences</a></li>
            <li><a href={'#outils'} onClick={() => setIsHidden(true)}>Outils</a></li>
            {/*<li><a href={'#projets'} onClick={() => setIsHidden(true)}>Projets</a></li>*/}
            <li><a href={'#formation'} onClick={() => setIsHidden(true)}>Formations</a></li>
            <li><a href={'#contact'} onClick={() => setIsHidden(true)}>Contact</a></li>
          </ul>
        </div>
      </div>
    );
}

export default  Navbar;
