import React from 'react';
import me from './images/me.png';
import './apropos.css';

const Apropos = () => {
    return (
      <div id="apropos">
          <img src={me} className="animate__animated animate__jackInTheBox" alt={'Hello ! Je me présente je suis Gabriel Chirouse !'}/>
          <div className={'texts animate__animated animate__bounce'}>
            <p>Hey ! Comment vas-tu ? Tu veux que je te fasse visiter ?</p>
            <p>
              Je me présente, Gabriel Chirouse, spécialisé dans la création de site web et d'application mobile,
              Je suis ici pour t'accompagner et te donner toutes les informations à ne pas manquer 😁
            </p>
            <p>
              Chacune de mes expériences mon permis de parfaire mes connaissances dans le développement d'applications logiciels et métiers.
            </p>
            <p>Près pour l'aventure !</p>
          </div>
      </div>
    );
}
export default Apropos;
