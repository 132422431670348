import React from 'react';
import mePc from './images/me-pc.png';
import './competences.css';

const Competences = () => {
    return (
      <div id="competences">
        <h2 className="text-3xl uppercase text-white">mes compétences</h2>
          <div id="me-pc">
            <img src={mePc} className="animate__animated animate__zoomInUp w-2/5 lg:w-1/6" alt={'Au boulot !'}/>
          </div>
      </div>
    );
};

export default Competences;
